// Colours
$colour-dark-blue: #114498;
$colour-sky-blue: #5cc2ec;
$colour-white: #ffffff;
$colour-grey-background: #f4f4f4;
$colour-dark-grey: #666666;
$colour-medium-grey: #959595;
$colour-light-grey: #c1c1c1;
$colour-off-white: #f6f6f6;
$colour-success: #87e687;
$colour-failure: #C62828;
$colour-orange: #f9ae2c;
// Banner
$colour-banner: $colour-dark-blue;
$colour-banner-heading: $colour-white;
// Text
$colour-section-heading: $colour-dark-blue;
$colour-label: $colour-dark-grey;
$colour-subtitle: $colour-medium-grey;
// Inputs
$colour-input: $colour-off-white;
$colour-input-value: $colour-sky-blue;
$colour-input-disabled: #d9d9d9;
$colour-input-value-disabled: $colour-dark-blue;
$colour-placeholder: $colour-light-grey;
$colour-input-border: #cfcfcf;
$colour-selected-radio-button: $colour-sky-blue;
$input-height: 2.7rem;
$input-height-mobile: 2.5rem;
// Slider
$colour-slider-value: $colour-sky-blue;
// Borders
$colour-border: #d0d0d0;
$colour-border-light: #ebebeb;
$border-weight: 0.075rem;
$thick-border-weight: 0.25rem;
$border-radius: 0.3rem;
// Buttons
$colour-button: $colour-sky-blue;
$colour-button-text: $colour-white;
// Links
$colour-link: $colour-sky-blue;
// Forms
$form-row-height: 2rem;
// Overlay
$overlay-height: 6rem;
$colour-overlay: #f9f9f9;
// Popup Overlay
$colour-popup-overlay: $colour-dark-grey;
// Side menu
$colour-side-menu: #73b0e0;
$colour-side-menu-header: #58a5e1;
$colour-side-menu-footer: $colour-dark-blue;
$colour-footer-text: #9fafc9;
// Icons
$arrow-icon-width: 0.6rem;
$info-icon-width: 1rem;
// Font Weights
$font-weight-extra-light: 200;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 600;
// Fonts
$font-size-extra-small: 0.75rem;
$font-size-small: 0.85rem;
$font-size-normal: 1rem;
$font-size-medium: 1.2rem;
$font-size-large: 1.4rem;
$font-size-extra-large: 2.4rem;
// Spacing
$spacing: 1rem; // 16px
$spacing-xs: ($spacing - 0.8); // 4px
$spacing-s: ($spacing - 0.5); // 12px
$spacing-l: ($spacing + 0.5); // 24px
$spacing-xl: ($spacing + 1); // 24px
// Breakpoints
$breakpoint-xxsmall: 400px;
$breakpoint-xsmall: 500px;
$breakpoint-small: 650px;
$breakpoint-medium: 850px;
$breakpoint-large: 1024px;
$breakpoint-xlarge: 1300px;



.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 0 5%;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 75%;
}

.topBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $spacing-s;
}

.logoContainer {
  img {
    width: 250px;
  }
}

.linksContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  p {
    padding-left: 1rem;
  }

  hr {
    width: 1px;
    height: 1.75rem;
    background: $colour-sky-blue;
    margin-left: $spacing;
  }

  .navLinks {
    color: $colour-dark-blue;
    font-size: $font-size-normal;
    font-weight: $font-weight-medium;
  }
}


.headerPanel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $colour-dark-blue;
  padding: 0 5%;

  p {
    margin-bottom: $spacing;
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  img {
    width: 200px;
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: $font-size-normal;
  border-radius: 2px;
  color: $colour-white;
  height: 2rem;
  width: 10rem;
  margin: $spacing-s 0;
}

.orangeButton {
  background-color: $colour-orange;
}


.welcomeContainer {
  display: flex;
  padding-top: $spacing-xs;

  div:nth-of-type(1) {
    flex: 1;
    padding-right: $spacing-xl;

    p {
      margin-bottom: $spacing-xs;
    }
  }

  div:nth-of-type(2) {
    flex: 2;
    margin-left: $spacing;
    padding-right: $spacing;
  }
}

.coreLogicTrusted {
  margin-left: $spacing-xl;
  width: 86px;
}

.reviewContainer {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  background-color: $colour-grey-background;
  padding: 2rem 0 $spacing 0;

  .review {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $colour-white;
    border: $colour-dark-blue 1px solid;
    border-radius: 10px;
    padding: $spacing .5rem;
    text-align: center;
    margin: $spacing;

    p {
      font-size: .75rem;
      margin-bottom: 0;
      margin-top: .25rem;
    }

    p:nth-of-type(1) {
      flex: 2;
      margin-bottom: 1rem;
    }
  }

  .testimonialstars {
    img {
      width: 45%;
    }
  }

  .testimonialImage {
    flex: 1;
    margin: $spacing;
    background-image: url("../../assets/static/testimonial-image.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.blurbContainer {
  margin: $spacing-xl 10%;
}

.profiles {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding-bottom: 1rem;

  img {
    width: 200px;
  }

  div {
    width: 30%;

    p {
      margin-bottom: 0;
    }

    .h5 {
      margin-top: .25rem;
    }

    span {
      margin-bottom: $spacing;
      padding: 0 $spacing;

      p {
        margin-top: $spacing-s;
      }
    }
  }

  .signUpBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    padding: 0;

    p {
      padding: 0;
    }
  }
}

.orangeBackground {
  background-color: $colour-orange;
}

.blueBackground {
  background-color: $colour-sky-blue;
}

.footer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: $spacing;
  background-color: $colour-dark-blue;
  height: 2.4em;

  p {
    color: $colour-footer-text;
    font-size: .75em;
    text-align: center;
  }
}

//text styles
.centered {
  text-align: center;
}

.h1 {
  color: $colour-white;
  font-size: 2rem;
  font-weight: $font-weight-medium;
  line-height: 1.3em;

  em {
    font-style: normal;
    line-height: 1.3em;
    border-bottom: 2px solid;
  }
}

.h2 {
  font-size: $font-size-large;
  font-weight: $font-weight-bold;
  color: $colour-dark-blue;
  line-height: 1.3em;

  em {
    font-style: normal;
  }
}

.h3 {
  color: $colour-dark-blue;
  font-size: $font-size-medium;
  font-weight: $font-weight-normal;
  line-height: 1.4em;

  em {
    font-style: italic;
    border-bottom: 1px solid;
  }
}

.h4 {
  color: $colour-dark-blue;
  font-size: $font-size-medium;
  font-weight: 700;
  line-height: 1.4em;
}

.h5 {
  color: $colour-sky-blue;
  font-size: $font-size-normal;
  font-weight: $font-weight-bold;
  font-style: italic;
  line-height: 1.4em;
}

.body {
  color: $colour-dark-grey;
  font-size: $font-size-normal;
  font-size: .9rem;
  line-height: 1.2em;

  em {
    font-style: normal;
    font-weight: 700;
    color: $colour-dark-blue;
  }
}

.orange {
  color: $colour-orange;
}

.blue {
  color: $colour-sky-blue;
}

.white {
  color: $colour-white;

  em {
    font-style: italic;
    border-bottom: 1px solid;
  }
}

.blurbText {
  color: $colour-dark-blue;
  font-size: $font-size-large;
  font-weight: $font-weight-light;
  line-height: 1.4em;
  text-align: center;
}


@media only screen and (max-width: $breakpoint-large) {
  .content {
    width: 100%
  }

  .h1 {
    font-size: $font-size-large;
  }

  .button {
    font-size: $font-size-normal;
  }

  .logoContainer {
    img {
      width: 225px;
    }
  }

  .linksContainer {
    .navLinks {
      font-size: $font-size-normal;
      font-weight: $font-weight-medium;
    }
  }
}

@media only screen and (max-width: $breakpoint-medium) {
  .h1 {
    font-size: 1.3rem;
  }

  .h2 {
    font-size: 1.1rem;
  }

  .h3 {
    font-size: $font-size-normal;
  }

  .h4 {
    font-size: $font-size-normal;
  }

  .h5 {
    font-size: $font-size-small;
  }

  .body {
    font-size: $font-size-small;
  }

  .button {
    font-size: $font-size-small;
  }

  .logoContainer {
    img {
      width: 200px;
    }
  }

  .linksContainer {
    .navLinks {
      font-size: $font-size-small;
    }

    hr {
      height: 1.5rem;
    }
  }

  .headerPanel {
    img {
      width: 150px;
    }
  }

  .profiles {
    img {
      width: 150px;
    }
  }
}

@media only screen and (max-width: $breakpoint-small) {
  .h1 {
    font-size: $font-size-normal;
  }

  .h2 {
    font-size: .9rem;
  }

  .h3 {
    font-size: $font-size-small;
  }

  .h4 {
    font-size: $font-size-small;
  }

  .h5 {
    font-size: $font-size-extra-small;
  }

  .body {
    font-size: $font-size-extra-small;
  }

  .button {
    font-size: $font-size-extra-small;
    height: 2rem;
    margin-bottom: $spacing;
  }

  .logoContainer {
    img {
      width: 150px;
    }
  }

  .linksContainer {
    .navLinks {
      font-size: $font-size-extra-small;
    }

    hr {
      height: 1.5rem;
    }
  }

  .headerPanel {
    img {
      width: 100px
    }
  }

  .welcomeContainer {
    flex-direction: column;

    div:nth-of-type(1) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-right: 0;
    }

    div:nth-of-type(2) {
      margin-left: 0;
      padding-right: 0;
    }
  }

  .coreLogicTrusted {
    margin-left: 0;
  }

  .reviewContainer {
    display: block;
    padding-top: $spacing-s;
    padding-bottom: $spacing-s;

    .testimonialImage {
      height: 200px;
    }
  }

  .profiles {
    flex-direction: column;
    align-items: center;

    img {
      width: 200px;
    }

    div {
      width: 100%;
      margin-bottom: $spacing;

      span {
        padding: 0 $spacing-xl;
      }
    }
  }

  .footer {
    p {
      font-size: .6em;
    }
  }
}
