ul.af_list {
  list-style: none;
  padding: 0;
  margin: 0;
  border: solid 1px #999;
  background: #fff;
  font-size: 15px;
  font-family: "Lato", sans-serif;
  z-index: 1001;
  li.af_item {
    cursor: pointer;
    padding: 5px 5px;
    overflow:hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border-bottom:1px solid #DBDBDB;

    .af_hl { font-weight: bold; }

    &.af_hover{
      background-color: #3194EB;
      color:#FFFFFF;
    }
  }
  li.af_error{
    padding: 5px 5px;
    background-color: lighten(#cc0000,25%);
    a{
      color: #333;
      text-decoration: underline;
      &:hover{
        text-decoration: none;
      }
    }
  }
  li.af_empty {
    padding: 5px 5px;
    border-bottom: 1px solid #DBDBDB;
  }
  li.af_footer{
    font-size: 13px;
    color: #666;
    text-align: right;
    background:#F0F0F0;
    padding:3px 5px;
    font-weight:bold;
    a {
      color: #666;
      text-decoration: none;
      span {
        text-decoration: underline;
      }
    }
    a:hover span {
      color: #3194EB;
    }
  }

li.af_info_panel { padding: 5px 30px 5px 5px;
   &.hidden {display: none;}
   background:#fff;
   z-index: 10;
   span.cancel_button {position:absolute; top:0; right: 0;
     &.IE {
       &:before {
         font-size: 25px;
         margin-top: -4px;
       }
     }
     &:hover:before {color:#4d4d4d;}
     &:before {
       content: '\2A2F';
       color: 	#999999;
       display: inline-block;
       font-size: 28px;
       margin-top: -12px;
       margin-right: 5px;
     }
   }
 }
}

.af-hidden-autofill-icon::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}

a.af_icon {position:absolute; z-index:1001; cursor:pointer; background: url('../static/icons/compass.svg') no-repeat 0 0; background-size: 100%; opacity: 0.75;
  &.af_hidden {display: none;}
  &:hover {background: url('../static/icons/compass.svg') no-repeat 0 0; background-size: 100%; opacity:1; }
  &.active {animation-name: pulsing-effect; animation-duration: 1.5s; animation-iteration-count: infinite;}

  @keyframes pulsing-effect {
    0%   {background: url('../static/icons/compass-loading.svg') no-repeat 0 0; background-size: 100%;}
    100% {background: url('../static/icons/compass.svg') no-repeat 0 0; background-size: 100%;}
  }
}

@media (hover: none) {
  a.af_icon:hover { background: url('../static/icons/compass.svg') no-repeat 0 0; background-size: 100%; opacity: 0.6;}
}
