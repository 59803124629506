// Colours
$colour-dark-blue: #114498;
$colour-sky-blue: #5cc2ec;
$colour-white: #ffffff;
$colour-grey-background: #f4f4f4;
$colour-dark-grey: #666666;
$colour-medium-grey: #959595;
$colour-light-grey: #c1c1c1;
$colour-off-white: #f6f6f6;
$colour-success: #87e687;
$colour-failure: #C62828;
$colour-orange: #f9ae2c;
// Banner
$colour-banner: $colour-dark-blue;
$colour-banner-heading: $colour-white;
// Text
$colour-section-heading: $colour-dark-blue;
$colour-label: $colour-dark-grey;
$colour-subtitle: $colour-medium-grey;
// Inputs
$colour-input: $colour-off-white;
$colour-input-value: $colour-sky-blue;
$colour-input-disabled: #d9d9d9;
$colour-input-value-disabled: $colour-dark-blue;
$colour-placeholder: $colour-light-grey;
$colour-input-border: #cfcfcf;
$colour-selected-radio-button: $colour-sky-blue;
$input-height: 2.7rem;
$input-height-mobile: 2.5rem;
// Slider
$colour-slider-value: $colour-sky-blue;
// Borders
$colour-border: #d0d0d0;
$colour-border-light: #ebebeb;
$border-weight: 0.075rem;
$thick-border-weight: 0.25rem;
$border-radius: 0.3rem;
// Buttons
$colour-button: $colour-sky-blue;
$colour-button-text: $colour-white;
// Links
$colour-link: $colour-sky-blue;
// Forms
$form-row-height: 2rem;
// Overlay
$overlay-height: 6rem;
$colour-overlay: #f9f9f9;
// Popup Overlay
$colour-popup-overlay: $colour-dark-grey;
// Side menu
$colour-side-menu: #73b0e0;
$colour-side-menu-header: #58a5e1;
$colour-side-menu-footer: $colour-dark-blue;
$colour-footer-text: #9fafc9;
// Icons
$arrow-icon-width: 0.6rem;
$info-icon-width: 1rem;
// Font Weights
$font-weight-extra-light: 200;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 600;
// Fonts
$font-size-extra-small: 0.75rem;
$font-size-small: 0.85rem;
$font-size-normal: 1rem;
$font-size-medium: 1.2rem;
$font-size-large: 1.4rem;
$font-size-extra-large: 2.4rem;
// Spacing
$spacing: 1rem; // 16px
$spacing-xs: ($spacing - 0.8); // 4px
$spacing-s: ($spacing - 0.5); // 12px
$spacing-l: ($spacing + 0.5); // 24px
$spacing-xl: ($spacing + 1); // 24px
// Breakpoints
$breakpoint-xxsmall: 400px;
$breakpoint-xsmall: 500px;
$breakpoint-small: 650px;
$breakpoint-medium: 850px;
$breakpoint-large: 1024px;
$breakpoint-xlarge: 1300px;



.logo {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 60px;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1,
  h2 {
    text-align: center;
    margin: $spacing-s 0;
  }
  h1 {
    font-size: 2rem;
    font-weight: $font-weight-normal;
  }
  h2 {
    color: $colour-white;
    font-size: $font-size-medium;
    font-weight: $font-weight-light;
    max-width: 700px;
  }
  .logoutLink {
    cursor: pointer;
    text-decoration: underline;
    font-size: $font-size-small;
    font-weight: bold;
    color: darken(#b3e6ff, 50%);
  }
  .panels {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    margin-top: $spacing-l;
    .panel {
      margin: $spacing-s 1vw;
      .panelButtons {
        justify-content: center;
        padding: $spacing-l 0 0;
      }
      .panelContent {
        background: $colour-white;
        padding: $spacing-xl;
        h3,
        h4 {
          text-align: center;
          color: $colour-dark-grey;
          margin: 0;
          padding: 0;
          font-size: larger;
        }
        h3 {
          margin-bottom: 0.2em;
        }
        h4 {
          font-weight: 600;
          span {
            font-size: smaller;
            font-weight: normal;
          }
        }
        ul {
          list-style: none;
          padding-left: 1.5em;
          position: relative;
          line-height: 1.7em;
          padding-top: $spacing-l;
          margin-top: $spacing-l;
          border-top: 1px solid $colour-light-grey;
        }
        .excludedItem {
          color: $colour-light-grey;
        }
        .includedItem {
          img {
            width: 15px;
            position: absolute;
            left: 0;
            padding-top: 9px;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: $breakpoint-xlarge) {
  .content h1 {
    font-size: 3.4rem;
  }
}
@media only screen and (min-width: $breakpoint-small) {
  .content {
    h1 {
      font-size: 2.5rem;
    }
    h2 {
      font-size: $font-size-large;
    }
  }
}

@media only screen and (min-width: $breakpoint-xxsmall) {
  .logo {
    width: 110px;
  }
}
