// Colours
$colour-dark-blue: #114498;
$colour-sky-blue: #5cc2ec;
$colour-white: #ffffff;
$colour-grey-background: #f4f4f4;
$colour-dark-grey: #666666;
$colour-medium-grey: #959595;
$colour-light-grey: #c1c1c1;
$colour-off-white: #f6f6f6;
$colour-success: #87e687;
$colour-failure: #C62828;
$colour-orange: #f9ae2c;
// Banner
$colour-banner: $colour-dark-blue;
$colour-banner-heading: $colour-white;
// Text
$colour-section-heading: $colour-dark-blue;
$colour-label: $colour-dark-grey;
$colour-subtitle: $colour-medium-grey;
// Inputs
$colour-input: $colour-off-white;
$colour-input-value: $colour-sky-blue;
$colour-input-disabled: #d9d9d9;
$colour-input-value-disabled: $colour-dark-blue;
$colour-placeholder: $colour-light-grey;
$colour-input-border: #cfcfcf;
$colour-selected-radio-button: $colour-sky-blue;
$input-height: 2.7rem;
$input-height-mobile: 2.5rem;
// Slider
$colour-slider-value: $colour-sky-blue;
// Borders
$colour-border: #d0d0d0;
$colour-border-light: #ebebeb;
$border-weight: 0.075rem;
$thick-border-weight: 0.25rem;
$border-radius: 0.3rem;
// Buttons
$colour-button: $colour-sky-blue;
$colour-button-text: $colour-white;
// Links
$colour-link: $colour-sky-blue;
// Forms
$form-row-height: 2rem;
// Overlay
$overlay-height: 6rem;
$colour-overlay: #f9f9f9;
// Popup Overlay
$colour-popup-overlay: $colour-dark-grey;
// Side menu
$colour-side-menu: #73b0e0;
$colour-side-menu-header: #58a5e1;
$colour-side-menu-footer: $colour-dark-blue;
$colour-footer-text: #9fafc9;
// Icons
$arrow-icon-width: 0.6rem;
$info-icon-width: 1rem;
// Font Weights
$font-weight-extra-light: 200;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 600;
// Fonts
$font-size-extra-small: 0.75rem;
$font-size-small: 0.85rem;
$font-size-normal: 1rem;
$font-size-medium: 1.2rem;
$font-size-large: 1.4rem;
$font-size-extra-large: 2.4rem;
// Spacing
$spacing: 1rem; // 16px
$spacing-xs: ($spacing - 0.8); // 4px
$spacing-s: ($spacing - 0.5); // 12px
$spacing-l: ($spacing + 0.5); // 24px
$spacing-xl: ($spacing + 1); // 24px
// Breakpoints
$breakpoint-xxsmall: 400px;
$breakpoint-xsmall: 500px;
$breakpoint-small: 650px;
$breakpoint-medium: 850px;
$breakpoint-large: 1024px;
$breakpoint-xlarge: 1300px;



.container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.topRow {
  display: flex;
  justify-content: space-between;
}

.logoContainer {
  flex: 1;
  padding-left: 3rem;
  padding-top: $spacing-s;

  img {
    height: 80px;
  }
}

.loginContainer {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  padding-right: 3rem;
  padding-top: $spacing-s;
  background-color: $colour-grey-background;
}

.linksContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  p {
    padding-left: 1rem;
  }

  hr {
    width: 1px;
    height: 2rem;
    background: $colour-sky-blue;
    margin-left: $spacing;
  }

  .navLinks {
    color: $colour-dark-blue;
    font-size: $font-size-large;
    font-weight: $font-weight-medium;
  }
}

.panelContainer {
  display: flex;
  flex-direction: row;
}

.panel {
  position: relative;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: $spacing-l $spacing 5% 0;
}

.leftPanel {
  background-color: $colour-white;
}

.rightPanel {
  background-color: $colour-grey-background;
}

.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0 2rem;
}

.titleContainer {
  padding: 0 3rem;
  height: 12rem;
}

.houseContainer {
  flex-grow: 1;
  flex: 1;
  display: flex;
  justify-content: space-around;
  align-items: center;

  img {
    width: 75%;
  }
}

.blurbContainer {
  flex-grow: 1;
  flex-shrink: 0;
  padding: 0 2.5rem 1rem 2.5rem;
}

.blurbText {
  color: $colour-dark-blue;
  font-size: $font-size-large;
  font-weight: $font-weight-light;
  line-height: 1.4em;
  text-align: center;
}


.button {
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: $font-size-normal;
  border-radius: 2px;
  color: $colour-white;
  height: 2.5rem;
  width: 10rem;
  padding: 0;
}

.orangeButton {
  background-color: $colour-orange;
}

//text styles
.headerText {
  color: $colour-dark-blue;
  font-size: 2rem;
  font-weight: $font-weight-medium;
  line-height: 1.3em;
  text-align: center;

  em {
    font-style: normal;
    border-bottom: 2px solid;
    padding-bottom: 2px;
    font-weight: $font-weight-bold;
  }

  .orange {
    color: $colour-orange;
  }

  .blue {
    color: $colour-sky-blue;
  }
}

@media only screen and (max-width: $breakpoint-xlarge) {
  .logoContainer {
    img {
      height: 60px;
    }
  }

  .titleContainer {
    padding: 0 2rem;
    height: 15rem;
  }
}

@media only screen and (max-width: $breakpoint-large) {
  .logoContainer {
    img {
      height: 50px;
    }
  }

  .linksContainer {
    .navLinks {
      font-size: $font-size-normal;
      font-weight: $font-weight-medium;
    }
  }

  .titleContainer {
    height: 9rem;
  }

  .headerText {
    font-size: $font-size-large;
    font-weight: $font-weight-normal;

    .orange,
    .blue {
      font-weight: $font-weight-medium;
      border-bottom: 1.5px solid;
      padding-bottom: 1px;
    }
  }

  .blurbText {
    font-size: $font-size-normal;
  }

  .button {
    font-size: $font-size-small;
  }
}

@media only screen and (max-width: $breakpoint-medium) {

  .panelContainer {
    flex-direction: column;
  }

  .logoContainer {
    padding-left: $spacing;
  }

  .loginContainer {
    padding-right: $spacing;
    background-color: white;
  }

  .titleContainer {
    height: auto;
    padding: 0 5rem;
  }

  .headerText {
    font-size: $font-size-large;
  }

  .blurbContainer {
    padding: 0 5rem 1rem 5rem;
  }
}

@media only screen and (max-width: $breakpoint-small) {

  .topRow {
    align-items: center;
  }

  .logoContainer {
    img {
      height: 30px;
    }
  }

  .loginContainer {
    background-color: white;
  }

  .linksContainer {
    .navLinks {
      font-size: $font-size-extra-small;
      font-weight: $font-weight-normal;
    }

    hr {
      height: 1.5rem;
    }
  }

  .titleContainer {
    height: auto;
    padding: 0;
  }

  .headerText {
    font-size: $font-size-normal;
  }

  .blurbContainer {
    padding: 0 1rem 1rem 1rem;
  }

  .blurbText {
    font-size: $font-size-extra-small;
  }

  .button {
    font-size: .65rem;
    width: 7rem;
    height: 2rem;
  }
}
