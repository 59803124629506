// Colours
$colour-dark-blue: #114498;
$colour-sky-blue: #5cc2ec;
$colour-white: #ffffff;
$colour-grey-background: #f4f4f4;
$colour-dark-grey: #666666;
$colour-medium-grey: #959595;
$colour-light-grey: #c1c1c1;
$colour-off-white: #f6f6f6;
$colour-success: #87e687;
$colour-failure: #C62828;
$colour-orange: #f9ae2c;
// Banner
$colour-banner: $colour-dark-blue;
$colour-banner-heading: $colour-white;
// Text
$colour-section-heading: $colour-dark-blue;
$colour-label: $colour-dark-grey;
$colour-subtitle: $colour-medium-grey;
// Inputs
$colour-input: $colour-off-white;
$colour-input-value: $colour-sky-blue;
$colour-input-disabled: #d9d9d9;
$colour-input-value-disabled: $colour-dark-blue;
$colour-placeholder: $colour-light-grey;
$colour-input-border: #cfcfcf;
$colour-selected-radio-button: $colour-sky-blue;
$input-height: 2.7rem;
$input-height-mobile: 2.5rem;
// Slider
$colour-slider-value: $colour-sky-blue;
// Borders
$colour-border: #d0d0d0;
$colour-border-light: #ebebeb;
$border-weight: 0.075rem;
$thick-border-weight: 0.25rem;
$border-radius: 0.3rem;
// Buttons
$colour-button: $colour-sky-blue;
$colour-button-text: $colour-white;
// Links
$colour-link: $colour-sky-blue;
// Forms
$form-row-height: 2rem;
// Overlay
$overlay-height: 6rem;
$colour-overlay: #f9f9f9;
// Popup Overlay
$colour-popup-overlay: $colour-dark-grey;
// Side menu
$colour-side-menu: #73b0e0;
$colour-side-menu-header: #58a5e1;
$colour-side-menu-footer: $colour-dark-blue;
$colour-footer-text: #9fafc9;
// Icons
$arrow-icon-width: 0.6rem;
$info-icon-width: 1rem;
// Font Weights
$font-weight-extra-light: 200;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 600;
// Fonts
$font-size-extra-small: 0.75rem;
$font-size-small: 0.85rem;
$font-size-normal: 1rem;
$font-size-medium: 1.2rem;
$font-size-large: 1.4rem;
$font-size-extra-large: 2.4rem;
// Spacing
$spacing: 1rem; // 16px
$spacing-xs: ($spacing - 0.8); // 4px
$spacing-s: ($spacing - 0.5); // 12px
$spacing-l: ($spacing + 0.5); // 24px
$spacing-xl: ($spacing + 1); // 24px
// Breakpoints
$breakpoint-xxsmall: 400px;
$breakpoint-xsmall: 500px;
$breakpoint-small: 650px;
$breakpoint-medium: 850px;
$breakpoint-large: 1024px;
$breakpoint-xlarge: 1300px;



.line {
  width: 80%;
  max-width: 400px;
  height: $thick-border-weight;
  background-color: $colour-sky-blue;
  margin: $spacing-l 0;
}